import React from 'react'

export const Header = () => {
    const handleTopHome = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className='zindex p-0'>
            <div className='row justify-content-between justify-content-lg-evenly justify-md-conent-center align-items-center logoHeader'>
                <div className='col-2 px-4 mt-3'><img src="img/header.png" border="0" width="50" alt="ae" /></div>
                <div className='col-10 px-4 mt-3 title-header'>
                    <h6>New Grand i10</h6>
                    <p className='subtext-header'>Tu seguridad no cuesta más.</p>
                </div>
            </div>
        </div>
    )
}