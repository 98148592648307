import React from "react";
import { Header } from './components/landing/Header';
import { Links } from './components/links/Links';
import { Share } from './components/links/Share';
import { Video } from './components/landing/Video';

class App extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
  let widthScreen = window.screen.width;
  return (
    <div className="w-100">
      <div className='row p-0 m-0'>
        <div className='col-12 col-lg-6 col-xl-6 col-xxl-6 p-0 m-0 white'>
          <div className="align-items-start mb-4">
            <div className="d-flex align-items-center heightMax">
              <div className="cardStyle">
                <Header />
                <Video />
                <Share />
                <div className="shareBorder"></div>
                <Links />
                <Links text='Conócelo ahora mismo' icon='icon-hyun' url='https://www.hyundai.cl/modelos/new-grand-i10-hatchback/' />
                <Links text='Facebook' icon='icon-fb' url='https://www.facebook.com/HyundaiChile' social="Facebook"/>
                <Links text='Instagram' icon='icon-ig' url='https://www.instagram.com/hyundaichile/' social="Instagram" />
                <Links text='LinkedIn' icon='icon-In' url='https://www.linkedin.com/company/hyundaichile/' social="LinkedIn"/>
                <div className="mb-4">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-none d-lg-block col-12 col-lg-6 col-xl-6 col-xxl-6 p-0 vh-100'>
          
        </div>
      </div>
    </div>
  );
}
}

export default App;
