import React, { useState } from "react";
import { InlineShareButtons } from 'sharethis-reactjs';

export const Links = ({ icon, url, text, social }) => {
    const [copied, setCopied] = useState(false);
    const copy = () => {
        window.dataLayer.push({
            event: "click",
            button_title: 'Compartir'
        });
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(!copied);
    }
    const handleClick = (e) => {
        e.preventDefault();
        window.dataLayer.push({
            event: "click",
            button_title: text ? text : 'Compartir'
        });
        window.open(url, '_blank')
    }
    return (
        <>
            { copied && <div className="mt-3 mb-3">
        <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 20,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            language: 'es',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'whatsapp',
              'linkedin',
              'facebook',
              'twitter',
              'instagram'
            ],
            padding: 10,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 30,             // the size of each button (INTEGER)
            // OPTIONAL PARAMETERS
            url: window.location.href, // (defaults to current url)
            image: window.location.href+'img/header.png',  // (defaults to og:image or twitter:image)
            description: 'A+E Networks Latin America',       // (defaults to og:description or twitter:description)
            title: 'A+E Networks Latin America',
          }}
        />
      </div> }
            <div className='linkComponent button-container cursor' onClick={url ? handleClick : copy}>
                <span className="icon-style button-icon">
                    {icon ? <img src={`img/${icon}.png`} width="30" height="30" alt={text} /> : <i className="fa-solid fa-share-nodes iconshare"></i>}
                </span>
                {url ? <span className="button-title">{text ? text : 'Compartir'}</span> : <span className="button-title">Compartir</span>}

                {url ? <span className="button-go">{social ? <span>IR</span> : <span>Conócelo</span>}</span>: ""}
                
            </div>
        </>
    )
}