import React, { useState } from "react";
import {InlineShareButtons} from 'sharethis-reactjs';
import { Social } from './Social';

export const Share = ({icon, url, text}) => {
    const [copied, setCopied] = useState(false);
    const copy = () =>{
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(!copied);
    }
const handleClick = (e) => {
    e.preventDefault();
    window.open(url,'_blank')
}
    return (
        <>
          {/*<div className="mt-3 mb-4">
            <div className="social-networks">
              <Social icon='FB' url='https://www.facebook.com/HyundaiChile' />
              <Social icon='IG' url='https://www.instagram.com/hyundaichile/' />
              <Social icon='LinkedIn' url='https://www.linkedin.com/company/hyundaichile/' />
              <Social icon='HYU' url='https://www.hyundai.cl/' />
            </div>
        </div>*/}
        </>
    )
}